import "../styles/footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy;PROJECT23 CREATIVE STUDIO</p>
      <p className="rightelem">LAST UPDATE : JULY 2024</p>
    </footer>
  );
};

export default Footer;
